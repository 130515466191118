export enum TagTypeEnum {
  EXERCISE = 'EXERCISE',
  SUB_CATEGORY = 'SUB_CATEGORY',
  TARGET_AREA = 'TARGET_AREA',
  MOVEMENT_PATTERN = 'MOVEMENT_PATTERN',
}

export enum TagCategoryEnum {
  STRENGTH = 'STRENGTH',
  CARDIO = 'CARDIO',
  FUNCTION = 'FUNCTION',
  MOBILITY = 'MOBILITY',
  LIFESTYLE = 'LIFESTYLE',
}
