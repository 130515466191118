<hidden-innovation-auth-layout>
  <div class='container-fluid p-0'>
    <div class='row mb-3'>
      <div class='col-12'>
        <h1 class='mat-h1 m-0 mb-1 font-sf-pro--bold'>Admin Login</h1>
        <p class='mat-subheading-2 font-calibri font-weight-normal text-muted mb-0'>Enter login credentials</p>
      </div>
    </div>
    <form [formGroup]='loginForm' class='row' (ngSubmit)='submit()'>
      <div class='col-12'>
        <div class='container-fluid p-0'>
          <div class="row mb-1">
            <div class='col-12'>
              <mat-form-field class='w-100'>
                <mat-label>Email</mat-label>
                <input matInput autocomplete='no' type="text" formControlName='email' required>
                <mat-error
                  *ngIf='loginForm.controls.email.errors?.required'>{{formValidationService.emailValidationMessage.required}}</mat-error>
                <mat-error
                  *ngIf='loginForm.controls.email.errors?.email'>{{formValidationService.emailValidationMessage.invalid}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class='row mb-2'>
            <div class='col-12'>
              <mat-form-field class='w-100'>
                <mat-label>Password</mat-label>
                <input hiddenInnovationDisableCopyPaste matInput formControlName='password' [type]="passwordHidden ? 'password' : 'text'">
                <button type='button' mat-icon-button matSuffix (click)='passwordHidden = !passwordHidden'
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]='passwordHidden'>
                  <mat-icon>{{passwordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error
                  *ngIf='loginForm.controls.password.errors?.required'>{{formValidationService.passwordValidationMessage.required}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class='row'>
            <div class='col-12 d-flex justify-content-between'>
              <button mat-flat-button color='primary' type='submit' [disabled]='authFacade.isLoginLoading$ | async'>
                Login
              </button>
              <a mat-button [routerLink]="['/forgot-password']">Forgot Password?</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</hidden-innovation-auth-layout>
